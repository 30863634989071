import React, {useState} from 'react';
import '../../components/menus/dropdown.scss';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { RiArrowDownDoubleFill } from 'react-icons/ri';


const NavMenus = ({item}) => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [dropdown, setDropdown] = useState(false);
   
    return (
        <>
            <li 
                className="navItem"
                onMouseEnter={() => setDropdown(true)}
                onMouseLeave={() => setDropdown(false)}
            >
                <Link to={item.path} className='link' onClick={scrollToTop}>
                    <span>{item.title}</span>
                    {item.children ? <RiArrowDownDoubleFill className='itemIcon' /> : ""}
                </Link>

                {dropdown && item.children && (
                    <ul className="dropdown-menu">
                        {item.children.map((dropDownItem) => (

                            <li key={dropDownItem.id} onClick={scrollToTop}>
                                <Link
                                    className={dropDownItem.cName}
                                    to={dropDownItem.path}
                                >
                                    <span>{dropDownItem.title}</span>
                                </Link>
                                <hr/>

                            </li>
                            
                        ))}

                    </ul>
                )}

            </li>
        </>
    )
}

export default NavMenus