/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './about.scss';
import { RiArrowDownDoubleFill } from 'react-icons/ri';

const Team = () => {

    const [readMore1, setReadMore1] = useState(false);
    const [readMore2, setReadMore2] = useState(false);
    const [readMore3, setReadMore3] = useState(false);

    const linkName1 = readMore1 ? 'Read Less' : 'Read Full Bio'
    const linkName2 = readMore2 ? 'Read Less' : 'Read Full Bio'
    const linkName3 = readMore3 ? 'Read Less' : 'Read Full Bio'

    const extraSusan = <div>
        <p className="extra-content">
            Her leadership encompasses overseeing all facets of Rego Foundation's mission to empower
            young women.
            With a proven track record of successfully managing complex initiatives, developing value-added
            programs, and enhancing organisational visibility, Susan is a visionary leader dedicated to
            creating lasting change.
            <a className="read-more-link" onClick={() => { setReadMore1(!readMore1) }}>
                <p>{linkName1}</p>
                <RiArrowDownDoubleFill />
            </a>
        </p>
    </div>

    const extraChristine = <div>
        <p className="extra-content">
        Christine is driven by a passion to connect like-minded individuals and organisations
        with Rego Foundation's mission, empowering young women to thrive. With a proven track
        record of developing scalable programs, she skillfully aligns partners' goals with critical
        issues and organisational priorities, fostering impactful collaborations.

            <a className="read-more-link" onClick={() => { setReadMore2(!readMore2) }}>
                <p>{linkName2}</p>
                <RiArrowDownDoubleFill />
            </a>
        </p>
    </div>

    const extraBarbara = <div>
        <p className="extra-content">
            Holding a Master of Arts in
            Sociology, Barbara possesses a deep understanding of program
            development, having crafted frameworks, plans, and indicators to measure project success.
            Her enthusiasm for sociological projects is evident in her leadership of operations research,
            where she skillfully designs and conducts studies to inform and enhance Rego Foundation's work.
            <a className="read-more-link" onClick={() => { setReadMore3(!readMore3) }}>
                <p>{linkName3}</p>
                <RiArrowDownDoubleFill />
            </a>
        </p>
    </div>


    return (
        <div className='team'>

            <div className="teamHero">
                <img src='/assets/team11.png' alt='' className='team1' />
                <img src='/assets/team3.png' alt='' className='team2' />
                <div className="top">
                    <p>OUR TEAM</p>
                    <h2>Meet Our Team</h2>
                    <span>
                    The executive leadership  at Rego Foundation is an impact-driven team of professionals passionate about
                        empowering individuals to thrive and achieve excellence.
                    </span>
                </div>
            </div>

            <div className="teamWrapper">

                <div className="teamItem">
                    <div className="top">
                        <img src="/assets/susan.png" alt=""/>
                        <span className="divider2" />
                        <div className="text">
                            <h3>Susan Hirego</h3>
                            <span>FOUNDER & TEAM LEAD</span>
                        </div>
                    </div>
                    <div className="bottom">
                        <span>
                        Susan is a multifaceted professional, excelling as both a Software Engineer and innovative Social Entrepreneur.
                         In 2016, she founded Rego Foundation to eliminate all
                         forms of violence and discrimination aganist women and girls in Uganda. As Chief Executive Officer for Rego Foundation,
                         Susan spearheads the foundation's strategic direction, driving national and global impact.


                        </span>

                        {!readMore1 &&
                            <a className="read-more-link" onClick={() => { setReadMore1(!readMore1) }}>
                                <p>{linkName1}</p>
                                <RiArrowDownDoubleFill />
                            </a>
                        }
                        {readMore1 && extraSusan}
                    </div>
                </div>

                <div className="teamItem">
                    <div className="top">
                        <img src="/assets/tina.png" alt="" />
                        <span className="divider2" />
                        <div className="text">
                            <h3>Christine Kisubi</h3>
                            <span>HEAD OF PARTNERSHIPS & FUNDING</span>
                        </div>
                    </div>
                    <div className="bottom">
                        <span>
                        Christine is a founding member and Head of Partnerships and Funding at Rego Foundation,
                         bringing a decade of expertise from public procurement offices to the role. She harnesses
                         her experience to lead the team in securing crucial funding from donors and ensures the
                         organisation's brand integrity through effective communication and marketing strategies.
                        </span>
                        {!readMore2 &&
                            <a className="read-more-link" onClick={() => { setReadMore2(!readMore2) }}>
                                <p>{linkName2}</p>
                                <RiArrowDownDoubleFill />
                            </a>
                        }
                        {readMore2 && extraChristine}
                    </div>
                </div>

                <div className="teamItem">
                    <div className="top">
                        <img src="/assets/barb.png" alt="" />
                        <span className="divider2" />
                        <div className="text">
                            <h3>Barbara Hirego</h3>
                            <span>HEAD OF PROGRAMS</span>
                        </div>
                    </div>
                    <div className="bottom">
                        <span>
                            Barbara, a dedicated Sociologist with a passion for driving impact, brings over
                            5 years of experience in rural-based project management to her role as Head of
                            Programs at Rego Foundation. As a founding member, she has played a pivotal role
                            in shaping the organisation's programs, leveraging her expertise to design and
                            implement initiatives that drive meaningful change.
                        </span>
                        {!readMore3 &&
                            <a className="read-more-link" onClick={() => { setReadMore3(!readMore3) }}>
                                <p>{linkName3}</p>
                                <RiArrowDownDoubleFill />
                            </a>
                        }
                        {readMore3 && extraBarbara}
                    </div>
                </div>

            </div>

            {/* <div className="trainerHeading">
                <h2>Lead Trainers</h2>
            </div> */}

            {/* <div className="leadTrainers">

                <div className="item">
                    <div className="imgWrapper">
                        <img src="/assets/nixon.png" alt="" />
                    </div>

                    <div className="text">
                        <h3>Nixon Ochatre</h3>
                        <span>Financial Literacy</span>
                    </div>
                </div>

                <div className="item">
                    <div className="imgWrapper">
                        <img src="/assets/tony.png" alt="" />
                    </div>

                    <div className="text">
                        <h3>Tony Muhwezi</h3>
                        <span>Computer Basics</span>
                    </div>
                </div>

                <div className="item">
                    <div className="imgWrapper">
                        <img src="/assets/joel.png" alt="" />
                    </div>

                    <div className="text">
                        <h3>Joel Mwanja</h3>
                        <span>Software Engineering</span>
                    </div>
                </div>


            </div> */}

        </div>
    )
}

export default Team