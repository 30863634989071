import React from 'react';
import './modules.scss';

const LiteracyInfo = () => {
    return (
        <div className='info'>

            <div className="wrapper">

                <div className="textWrapper">
                    <span>Our goal for Financial Literacy is to impart knowledge, skills and confidence in young women
                        and girls to manage their personal, family, and business finances well.
                    </span>

                    <span>
                        This curriculum has been adopted from "Simply Money, Magnify Life" training manual by Bank of Uganda
                        as well as our Certifified Financial Literacy Consultants to target young women and girls
                        involved in informal economic activities.
                    </span>

                    <span>Sheboss financial literacy training is specifically designed to teach young women and girls the concepts of money and how to manage
                        it wisely. It offers the opportunity to learn basic skills related to personal financial management,
                        savings, loan management, investment, insurance, planning for old age/retirement and financial
                        service providers.</span>

                </div>

                <div className="imageWrapper">
                    <img src="/assets/group1.png" alt="" />
                </div>
                
            </div>

        </div>
    )
}

export default LiteracyInfo