import React from 'react';
import './modules.scss';
import { HiCheckCircle } from 'react-icons/hi';

const Literacy3 = () => {
    return (
        <div className='module'>

            <h3>Loan Management</h3>

            <div className="mainWrapper">

                <div className="wrapper">

                    <p>In this module, participants will be learning how to handle their loans successfully.</p>

                    <span>Course Topics:</span>

                    <div className="timeTable">

                        <ul className='module-lists'>
                            <li>Good Loans/Bad Loans</li>
                            <li>The Costs of Borrowing</li>
                            <li>Interest Rates Calculations</li>
                            <li>How Much Debt Can You Afford?</li>
                            <li>The Dangers of Over-Indebtedness and Default</li>
                        </ul>
                    </div>

                </div>


                <div className="outcome">
                    <h3>Course Outcomes...</h3>
                    <div className='items'>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>What, when, how and where to borrow</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Identifying a suitable loan product</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Preparing a loan repayment schedule</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Literacy3