import React, { useEffect, useState } from 'react';
import './featured.scss';
import { shebossData } from '../../dummyData';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


const FeaturedSheboss = () => {

    
    const productItems = shebossData.map((item) => {

        return (
            <div className="carouselItems">
                <div className='imageCard' key={item.id}>

                    <div className="image">
                        <img src={item.img} alt="" className='mainImg' />
                    </div>

                </div>
            </div>
        )
    });

    const responsive = {
        0: { items: 2, },
        576: { items: 3.1 },
        720: { items: 3.1 },
        1024: { items: 4 },
    };



    return (
        <div className='featuredImgaes'>

            <div className="carousel">
                <AliceCarousel
                    infinite
                    autoPlay
                    mouseTracking
                    items={productItems}
                    disableDotsControls
                    disableButtonsControls
                    responsive={responsive}
                    autoPlayInterval={400}
                    animationDuration={3000}
                />
            </div>
        </div>
    )
}

export default FeaturedSheboss