import React from 'react';
import './app.scss';
import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import { Outlet,createBrowserRouter, RouterProvider } from 'react-router-dom';
import Footer from './components/footer/Footer';
import About from './pages/about/About';
import Team from './pages/about/Team';
import Sheboss from './pages/programs/Sheboss';
import Developers from './pages/programs/Developers';
import Savings from './pages/programs/Savings';
import Volunteer from './pages/involve/Volunteer';
import Impact from './pages/about/Impact';
import Contact from './contact/Contact';

const App = () => {

    //MAIN LAYOUT for homepage and profilepage
    const Layout = () => {
        return (
            <div>
                <Navbar />
                <div >
                    <Outlet />
                </div>
                <Footer/>
            </div>
        )
    };

    //ROUTER
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <Layout />
            ),
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/about",
                    element: <About />,
                },
                {
                    path: "/team",
                    element: <Team />,
                },
                {
                    path: "/impact-stories",
                    element: <Impact />,
                },
                {
                    path: "/sheboss-entreprenuership",
                    element: <Sheboss />,
                },
                {
                    path: "/tech-scholars",
                    element: <Developers />
                },
                {
                    path: "/regonow-savings",
                    element: <Savings />
                },
                {
                    path: "/get-involved",
                    element: <Volunteer />,
                },
                {
                    path: "/contact-us",
                    element: <Contact />,
                },





            ]
        },


    ])


    return (
        <div className='container' id="container" >
            <RouterProvider router={router} />
        </div>
    )
}

export default App