import React from 'react';
import './programs.scss';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import { MdArrowRightAlt } from 'react-icons/md';

const Developers = () => {
    return (
        <div className='developers'>

            <div className="devHero">
                <img src='/assets/dev.png' alt='' />
                <div className="top">
                    <h2>Tech Scholars Program</h2>
                    <p>Software Development Designed For Women</p>
                </div>
            </div>

            <div className="detailsWrapper">

                <div className="left">

                    <div className="items">

                        <div className="itemTop">
                            <h3>Program Overview</h3>
                            <span>
                                The Rego Foundation Tech Scholars Program is a comprehensive and practical training initiative
                                that empowers young women with cutting-edge software development skills enabling them to thrive
                                in the tech industry and serve as inspiring role models for women and girls in their communities,
                                promoting gender diversity and digital inclusion.
                            </span>
                        </div>

                        <div className="itemBottom">
                            <img src='/assets/dev1.png' alt='' />
                        </div>

                    </div>

                    <div className="compHeading">
                        <h3>Program Components</h3>
                    </div>

                    <div className="compWrapper">

                        <div className="components">
                            <div className="compLeft">
                                <h3>Full-stack Web Development Course</h3>
                                <span>
                                    Designed for individuals with little to no programming experience,
                                    this course offers a structured pathway to becoming a software developer in
                                    just 6-12 months. Through guided instruction and hands-on training, students will
                                    gain the skills and confidence needed to succeed in the field. Additionally, this
                                    course will thoroughly prepare students for technical interviews, equipping them with
                                    the knowledge and expertise required to excel in their next tech role and launch a
                                    successful career in software development.
                                </span>

                            </div>

                            <div className="compRight">
                                <img src='/assets/dev3.png' alt='' />
                            </div>

                        </div>

                        <div className="components">
                            <div className="compLeft">
                                <h3>Internship Experience</h3>
                                <span>
                                    Towards the end of the classroom training, beneficiaries take part in a
                                    Technical Interview Week, where they engage in multiple interviews with
                                    various teams from our partner organisations. Following this, they are matched
                                    with one of the teams they interviewed with, and embark on a five-month internship
                                    experience, applying their skills and knowledge in a real-world setting.
                                </span>
                            </div>

                            <div className="compRight">
                                <img src='/assets/dev2.png' alt='' />
                            </div>

                        </div>

                        <div className="components">
                            <div className="compLeft">
                                <h3>Job Search Support</h3>
                                <span>
                                    Upon completing our comprehensive program, including classroom training,
                                    career development, and internship, beneficiaries who are not retained by
                                    the host organisation are recognised as Rego Foundation Tech Scholars graduates.
                                    As part of our ongoing support, these graduates are entitled to receive job
                                    search assistance for up to six months after graduation and lifetime membership
                                    to our developers community, empowering them to secure employment and launch their careers.
                                </span>
                            </div>

                            <div className="compRight">
                                <img src='/assets/dev4.png' alt='' />
                            </div>

                        </div>

                    </div>

                </div>

                <div className="right">

                    {/* <div className="selection">
                        <h3>Selection Process and Criteria</h3>
                        <MdArrowRightAlt className='secIcon' />
                    </div> */}

                    <div className="highlight">

                        <h3>Program Highlights:</h3>

                        <div className="highItem">
                            <div className="title">
                                <RiCheckboxCircleFill className='highIcon' />
                                <h4>Comprehensive Curriculum:</h4>
                            </div>
                            <span>
                                We offer a broad spectrum
                                of programming languages and innovative technologies, carefully
                                curated to address the evolving needs of the tech industry and prepare you for success.
                            </span>
                        </div>

                        <div className="highItem">
                            <div className="title">
                                <RiCheckboxCircleFill className='highIcon' />
                                <h4>Experienced Instructors:</h4>
                            </div>
                            <span>
                                Benefit from the expertise of seasoned professionals and dedicated educators who
                                are invested in your growth and success. They'll offer personalized guidance, mentorship,
                                and support every step of the way.
                            </span>
                        </div>

                        <div className="highItem">
                            <div className="title">
                                <RiCheckboxCircleFill className='highIcon' />
                                <h4>Hands-on Projects: </h4>
                            </div>
                            <span>
                                Develop your programming expertise by tackling authentic, real-world projects that test your
                                abilities and push you to new heights. Through hands-on experience, you'll refine your
                                skills, build a portfolio of work, and gain the confidence to tackle complex programming
                                challenges.
                            </span>
                        </div>

                        <div className="highItem">
                            <div className="title">
                                <RiCheckboxCircleFill className='highIcon' />
                                <h4>Pair Programming:</h4>
                            </div>
                            <span>
                                Build lasting connections and a network of peers by working alongside fellow
                                participants on group projects, mirroring the collaborative dynamics of real-world
                                development teams.
                            </span>
                        </div>

                        <div className="highItem">
                            <div className="title">
                                <RiCheckboxCircleFill className='highIcon' />
                                <h4>Career Coaching:</h4>
                            </div>
                            <span>
                                Get expert support to enhance your employability and successfully enter the tech industry.
                                Our program offers personalised guidance on crafting a standout resume, reparing for interviews
                                and effective job search strategies.
                            </span>
                        </div>

                        <div className="highItem">
                            <div className="title">
                                <RiCheckboxCircleFill className='highIcon' />
                                <h4>Work Experience:</h4>
                            </div>
                            <span>
                                Connect with our network of partner organisations for exciting internship
                                opportunities, providing a gateway to hands-on experience, professional growth, and a
                                head start in your career.
                            </span>
                        </div>

                    </div>

                    <div className="whoAttends">
                        <h3>Who Should Attend:</h3>
                        <span> The Rego Foundation Tech Scholars Program is open to young women who meet the following criteria:</span>
                        <div className="list">
                            <RiCheckboxCircleFill className='highIcon' />
                            <h4>Community Change Makers</h4>
                        </div>
                        <div className="list">
                            <RiCheckboxCircleFill className='highIcon' />
                            <h4>Young women whose academic ambitions exceed their financial resources</h4>
                        </div>
                        <div className="list">
                            <RiCheckboxCircleFill className='highIcon' />
                            <h4>Refugees and Teenage mothers</h4>
                        </div>

                    </div>

                    <div className="whoAttends">
                        <h3>Prerequisites:</h3>
                        <span>No prior programming experience is required, but a passion for technology and a willingness to learn are essential.</span>
                    </div>

                    <div className="whoAttends">
                        <h3>Duration:</h3>
                        <span>
                            Our immersive merit-based Full-stack Development training includes a 28-weeks intensive
                            collaborative learning environment, individualized support, career coaching and 5 months of
                            applied learning internship.
                        </span>
                    </div>

                    <div className="whoAttends">
                        <h3>Certificate:</h3>
                        <span>
                            Upon graduating, participants will be awarded a
                            certificate of completion, recognising their mastery of programming skills and
                            dedication to the program.
                        </span>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Developers