import React from 'react';
import './list.scss';

const LiteracyList = ({id, active, setSelected}) => {
    return (
        <li
            className={active ? "module-listItem active" : "module-listItem"}
            onClick={() => setSelected(id)}
        >
            {id}
        </li>
    )
}

export default LiteracyList