import React from 'react';
import './modules.scss';
import { HiCheckCircle } from 'react-icons/hi';

const Literacy7 = () => {
    return (
        <div className='module'>

            <h3>Financial Service Providers</h3>

            <div className="mainWrapper">

                <div className="wrapper">

                    <p> This module to help participants to learn how financial service providers work, the types 
                        of products they offer, and how to choose the right type of service provider for their financial needs.
                       
                    </p>

                    <span>Course Topics:</span>

                    <div className="timeTable">

                        <ul className='module-lists'>
                            <li>Sources of Financial Services</li>
                            <li>Financial Products and Financial needs</li>
                            <li>Advantages of Formal Financial Services</li>
                            <li>Choosing an Account</li>
                            <li>How to use a Bank</li>
                        </ul>
                    </div>

                </div>


                <div className="outcome">
                    <h3>Course Outcomes...</h3>
                    <div className='items'>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Financial inclusion</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Why, when, how and where to use financial services</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Visiting / meeting a financial service providers</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Literacy7