import React from 'react';
import './involve.scss';
import { Link } from 'react-router-dom';
import { MdArrowRightAlt } from 'react-icons/md';

const Volunteer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }


    return (
        <div className='volunteer'>

            <div className="involveHero">
                <img src='/assets/involve.png' alt='' />
                <div className="top">
                    <h2>Get Involved</h2>
                </div>
            </div>

            <div className="itemWrapper">

                <div className="item">
                    <div className="left">
                            <img src='/assets/mentor2.png' alt='' />
                    </div>
                    <div className="right">
                        <p className='titles'>MENTORS</p>
                        <h3>Become a Tech Mentor</h3>
                        <span>
                            Are you looking to use your Technical skills for a good cause?
                            Join us for the opportunity to make a positive impact on individuals or
                            communities that may not have easy access to technology education and resources.
                        </span>
                        <span>
                            Based on your  availability, mentors have scheduled sessions with participants at least 90 minutes per week
                            to track their progress.
                        </span>

                        <a
                            href="https://forms.gle/5XSN1MoS1kxUBnqJA"
                            target="_blank" rel="noreferrer"
                            className='saveBtn'
                            style={{ textDecoration: 'none', color: 'white' }}
                        >
                            <div className="apply">
                                <p>Mentor Application</p>
                                <MdArrowRightAlt className='applyIcon' />
                            </div>
                        </a>


                    </div>
                </div>

                <div className="item2">

                    <div className="left">
                        <p className='titles'>PARTNERS</p>
                        <h3>Host a Dev Intern</h3>
                        <span>
                            Host Organisations are crucial to the success of our Software Development Bootcamps.
                        </span>
                        <span>
                            After technical training, each participant is linked to a Host Organisation
                            for a three / five months internship experience.
                        </span>

                        <Link to="/contact-us" className='link' onClick={scrollToTop}>
                            <div className="apply">
                                <p>Host an Intern</p>
                                <MdArrowRightAlt className='applyIcon' />
                            </div>
                        </Link>

                    </div>

                    <div className="right">
                        <img src='/assets/mentor.png' alt='' />
                    </div>

                </div>

                <div className="item">
                    <div className="left">
                            <img src='/assets/mentor1.png' alt='' />
                    </div>
                    <div className="right">
                        <p className='titles'>VOLUNTEERS</p>
                        <h3>Volunteer With Us</h3>
                        <span>
                       Rego Foundation provides you opportunities to make an impact by sharing
                       your professional skills and expertise with underserved communities and vulnerable groups within our scope.
                        </span>
                        <span>
                        Volunteers are welcome to apply for roles in fundraising, monitoring and evaluation,
                        marketing and communication, research, advocacy and technology.
                        </span>

                        <a
                            href="https://forms.gle/vyZzvSd5GJxjT6336"
                            target="_blank" rel="noreferrer"
                            className='saveBtn'
                            style={{ textDecoration: 'none', color: 'white' }}
                        >
                            <div className="apply">
                                <p>Volunteer Application</p>
                                <MdArrowRightAlt className='applyIcon' />
                            </div>
                        </a>

                    </div>
                </div>

            </div>


        </div>
    )
}

export default Volunteer