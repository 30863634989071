import React from 'react';
import './footer.scss';
import { FaFacebookF } from 'react-icons/fa';
import { MdOutlineCopyright } from 'react-icons/md';
import { GrInstagram, GrTwitter } from 'react-icons/gr';
import { TfiLocationPin, TfiEmail } from 'react-icons/tfi';
import { Link } from 'react-router-dom';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className="mainFooter">

            <div className="footerTop">

                <div className="item">
                    <h3>Organisation</h3>
                    <Link to="/about" className='link' onClick={scrollToTop}>
                        <span>Who We Are</span>
                    </Link>
                    <Link to="/team" className='link' onClick={scrollToTop}>
                        <span>Our Team</span>
                    </Link>
                    <Link to="/impact-stories" className='link' onClick={scrollToTop}>
                        <span>Impact Stories</span>
                    </Link>
                </div>

                <div className="item">
                    <h3>Programs</h3>
                    <Link to="/sheboss-entreprenuership" className='link' onClick={scrollToTop}>
                        <span>SheBoss Program</span>
                    </Link>
                    <Link to="/regonow-savings" className='link' onClick={scrollToTop}>
                        <span>Regonow - Group Savings</span>
                    </Link>
                    <Link to="/tech-scholars" className='link' onClick={scrollToTop}>
                        <span>Tech Scholars Program</span>
                    </Link>

                </div>

                <div className="item2">
                    <h3>Contact</h3>
                    <div className="itemWrapper">
                        <TfiLocationPin className='icon' />
                        <div className="text">
                            <h3>Plot 505, Nakisunga</h3>
                            <h3 className='address'>P. O. Box 25760</h3>
                            <h3 className='address'>Mukono, Uganda</h3>
                        </div>
                    </div>

                    <div className="itemWrapper">
                        <TfiEmail className='icon' />
                        <div className="text">
                            <span>info@regofoundation.org</span>
                            <p>We reply within 24 hours</p>
                        </div>
                    </div>

                </div>

            </div>

            <div className="hrWrapper">
                <hr />
            </div>


            <div className='footerBottom'>
                <div className="top">
                    <h3>Call us on (+256) 775326157</h3>
                    <p>Mon-Fri, 8am until 5pm</p>
                </div>

                <div className="bottom">
                    <div className="left">
                        <MdOutlineCopyright />
                        <span>2023</span>
                        <span>Rego Foundation</span>
                        <span>|</span>
                        <span>All Rights Reserved</span>
                    </div>

                    <div className="right">
                        <span>Follow us:</span>
                        <a href="https://www.facebook.com/regofoundation/" target='blank' className='a-link'>
                            <div className="rightItem">
                                <FaFacebookF className='icon' />
                            </div>
                        </a>
                        <a href="https://www.instagram.com/regofoundation/?hl=en" target='blank' className='a-link'>
                            <div className="rightItem">
                                <GrInstagram className='icon' />
                            </div>
                        </a>

                        <a href="https://twitter.com/RegoFoundation" target='blank' className='a-link'>
                            <div className="rightItem">
                                <GrTwitter className='icon' />
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer