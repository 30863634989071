import React, { useState } from 'react';
import './about.scss';
import { HiCheckCircle } from 'react-icons/hi';
import { MdKeyboardDoubleArrowRight, MdOutlineDoubleArrow } from 'react-icons/md';


const About = () => {

    const [apply, setApply] = useState({
        firstname: "",
        lastname: "",
        email: "",
    });

    const handleChange = (e) => {
        setApply((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    return (
        <div className='about'>
            <div className="aboutHero">
                <img src='/assets/whoweare1.png' alt='' className='aboutImg1' />
                <img src='/assets/whoweare2.png' alt='' className='aboutImg2' />
                <img src='/assets/whoweare3.png' alt='' className='aboutImg3' />
                <div className="top">
                    <h2>Who We Are</h2>
                </div>
            </div>

            <div className="aboutWhy">
                <div className="left">
                    <h4>Join the Rego Foundation Community</h4>
                    <span>Stay updated on our latest resources, events, and opportunities!</span>
                    <form>
                        <input
                            name="firstname"
                            type="text"
                            required
                            placeholder="First Name"
                            onChange={handleChange}
                            value={apply.firstname}
                        />

                        <input
                            name="lastname"
                            type="text"
                            required
                            placeholder="Last Name"
                            onChange={handleChange}
                            value={apply.lastname}
                        />

                        <input
                            name="email"
                            type="text"
                            required
                            placeholder="Email Address"
                            onChange={handleChange}
                            value={apply.email}
                        />

                        <button type="submit">
                            <p>Submit</p>
                            <MdKeyboardDoubleArrowRight className="aboutIcon" />
                        </button>
                    </form>
                </div>

                <div className="right">
                    <p>WHO WE ARE</p>
                    <h3> Rego Foundation provides a safe and enabling environment for young women and
                        to learn, pursue their dreams and become self-sufficient.
                    </h3>
                    <span>
                        Since its inception in 2016, Rego Foundation is dedicated to enhancing the socio-economic well-being of
                        young women and girls living in rural and urban-poor communities.
                    </span>

                    <span>We are unlocking the potential of young women and girls by creating access to competitive skills, formal
                        employment opportunities and financial capital in the form of credit and savings.
                    </span>
                </div>

            </div>

            <div className="aboutObject">

                <div className="objectLeft">
                    <img src='/assets/object3.png' alt='' />
                </div>

                <div className="objects">

                    <div className="objectWrapper ">
                        <p>PRIORITY DOMAINS</p>
                        <h3>Our Objectives:</h3>

                        <ul>
                            <li>
                                <div className="heading">
                                    <HiCheckCircle className='valueIcon' />
                                    <span>Women's Economic Empowerment</span>
                                </div>
                                <p>Increase women's access to finance and development of additional livelihoods</p>
                            </li>
                            <li>
                                <div className="heading">
                                    <HiCheckCircle className='valueIcon' />
                                    <span>Promote Female Stem Education</span>
                                </div>
                                <p>Increase women's access to market-relevant skills through Technical Skills Training</p>
                            </li>
                            <li>
                                <div className="heading">
                                    <HiCheckCircle className='valueIcon' />
                                    <span>Provide a safe space for women and girls</span>
                                </div>
                                <p>Promote awareness, prevention and care for women and girls subjected to violence</p>
                            </li>


                        </ul>

                    </div>

                </div>

            </div>

            <div className="aboutMain">

                <div className="center">
                    <h3>Our Mission</h3>
                    <span>
                    To inspire, educate and empower young women to unlock their
                    full potential and achieve self-sufficiency through mentorship, skills training and technology..
                    </span>
                </div>

                <div className="left">
                    <h3>Our Vision</h3>
                    <span>
                        A peaceful, free and equitable society where women and girls are incharge of the decisions that affect their lives.
                    </span>
                </div>

                <div className="right">
                    <h3>Our Values</h3>
                    <ul>
                        <li>
                            <MdOutlineDoubleArrow className='valueIcon' />
                            <span>Integrity
                            </span>
                        </li>
                        <li>
                            <MdOutlineDoubleArrow className='valueIcon' />
                            <span>Collaboration</span>
                        </li>
                        <li>
                            <MdOutlineDoubleArrow className='valueIcon' />
                            <span>Innovation</span>
                        </li>
                        <li>
                            <MdOutlineDoubleArrow className='valueIcon' />
                            <span>Excellence</span>
                        </li>

                    </ul>
                </div>

            </div>

        </div>
    )
}

export default About