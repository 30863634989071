import group2 from './assets/group2.png';
import girl1 from './assets/girl1.png';
import girl2 from './assets/girl2.png';
import girl3 from './assets/girl3.png';
import girl4 from './assets/girl4.png';
import girl5 from './assets/girl5.png';
import girl6 from './assets/girl6.png';
import girl7 from './assets/girl7.png';
import girl8 from './assets/girl8.png';
import girl9 from './assets/girl9.png';
import girl10 from './assets/girl10.png';
import girl11 from './assets/girl11.png';
import girl12 from './assets/girl12.png';
import biz from './assets/biz.png';




export const shebossData = [
  
    {
        id: 1,
        img: girl8,
    },
    {
        id: 2,
        img: girl2,
    },
    {
        id: 3,
        img: girl10,
    },
    {
        id: 4,
        img: girl1,
    },
    {
        id: 5,
        img: group2,
    },
    {
        id: 6,
        img: girl4,
    },

    {
        id: 7,
        img: girl6,
    },

    {
        id: 8,
        img: girl5,
    },

    {
        id: 9,
        img: girl7,
    },
    {
        id: 10,
        img: girl9,
    },
    {
        id: 10,
        img: biz,
    },
    {
        id: 11,
        img: girl3,
    },
    {
        id: 12,
        img: girl11,
    },
    {
        id: 13,
        img: girl12,
    },
    

]