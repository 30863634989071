/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { useState } from "react";
import "./navbar.scss";
import { FaBars, FaTimes, FaPlus, FaMinus } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { navItems } from '../menus/menuItems';
import NavMenus from './NavMenus';



const Navbar = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [open, setOpen] = useState(false);
    const [openAbout, setOpenAbout] = useState(false);
    const [openPrograms, setOpenPrograms] = useState(false);


    return (
        <div className="navbar active">

            <div className="wrapper">

                <div className="left">
                    <Link to="/">
                        <img className="logo" src="/logo.png" alt="" />
                    </Link>
                </div>

                <div className="right">

                    <div className="navbar-links">
                        <ul className="navList">
                            {navItems.map((item) => (
                                <NavMenus key={item.id} item={item} />
                            ))}
                        </ul>
                    </div>

                    <div className="buttons">
                        <a href='https://flutterwave.com/donate/ernc7vimvzyw' target="_blank" className='donateBtn'>
                            Donate
                        </a>
                    </div>


                </div>

            </div>


            <div className="hamburger" onClick={() => setOpen(!open)}>
                {open ? <FaTimes className="icon" /> : <FaBars className="icon" />}
            </div>

            <ul className="menu" style={{ right: open ? "0px" : "-100vw" }}>

                <li className="menuItem" onClick={() => setOpen(false)}>
                    <Link to="/" className='link' onClick={scrollToTop}>
                        <span>Home</span>
                    </Link>
                </li>


                <li className="menuItem" onClick={() => setOpenAbout(!openAbout)}>
                    <span>About</span>
                    {openAbout ? <FaMinus className='dropIcon' /> : <FaPlus className='dropIcon' />}
                </li>

                {openAbout && (
                    <div className="options" onClick={() => setOpenAbout(!openAbout)}>
                        <li className="menuItem2" onClick={() => setOpen(false)}>
                            <Link to="/about" className='link' onClick={scrollToTop}>
                                <span>Who We Are</span>
                            </Link>
                        </li>
                        <li className="menuItem2" onClick={() => setOpen(false)}>
                            <Link to="/team" className='link' onClick={scrollToTop}>
                                <span> Our Team</span>
                            </Link>
                        </li>
                        <li className="menuItem2" onClick={() => setOpen(false)}>
                            <Link to="/impact-stories" className='link' onClick={scrollToTop}>
                                <span>Impact Stories</span>
                            </Link>
                        </li>


                    </div>
                )}

                <li className="menuItem" onClick={() => setOpenPrograms(!openPrograms)}>
                    <span>Programs</span>
                    {openPrograms ? <FaMinus className='dropIcon' /> : <FaPlus className='dropIcon' />}
                </li>

                {openPrograms && (
                    <div className="options" onClick={() => setOpenPrograms(!openPrograms)}>
                        <li className="menuItem2" onClick={() => setOpen(false)}>
                            <Link to="/sheboss-entreprenuership" className='link' onClick={scrollToTop}>
                                <span>SheBoss Program</span>
                            </Link>
                        </li>
                        <li className="menuItem2" onClick={() => setOpen(false)}>
                            <Link to="/regonow-savings" className='link' onClick={scrollToTop}>
                                <span>Regonow - Group Savings</span>
                            </Link>
                        </li>
                        <li className="menuItem2" onClick={() => setOpen(false)}>
                            <Link to="/tech-scholars" className='link' onClick={scrollToTop}>
                                <span>Tech Scholars Program</span>
                            </Link>
                        </li>
                    </div>
                )}

                <li className="menuItem" onClick={() => setOpen(false)}>
                    <Link to="/get-involved" className='link' onClick={scrollToTop}>
                        <span>Get Involved</span>
                    </Link>
                </li>


                <li className="menuItem3" onClick={() => setOpen(false)}>
                    <a href='https://flutterwave.com/donate/ernc7vimvzyw' target="_blank" className='donateBtn2'>
                        Donate
                    </a>
                </li>


            </ul>

        </div>
    );
};

export default Navbar;