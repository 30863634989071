import React from 'react';
import './modules.scss';
import { HiCheckCircle } from 'react-icons/hi';


const Literacy1 = () => {
    return (
        <div className='module'>

            <h3>Personal Financial Management</h3>

            <div className="mainWrapper">

                <div className="wrapper">

                    <p>
                        This course will teach participants how to plan and manage their money. They will learn to set financial goals, make a
                        budget and track how their cash flows to make better financial decisions.
                    </p>

                    <span>Course Topics:</span>

                    <div className="timeTable">

                        <ul className='module-lists'>
                            <li>Set Financial Goals</li>
                            <li>Examine Your Money Management</li>
                            <li>Describe the Importance of a Budget</li>
                            <li>Make a Budget</li>
                            <li>Make Spending Decisions</li>
                        </ul>
                    </div>

                </div>


                <div className="outcome">
                    <h3>Course Outcomes...</h3>
                    <div className='items'>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Improved knowledge and confidence in financial literacy</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Setting personal SMART financial goals</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Preparing and tracking a budget</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Literacy1