import React from 'react';
import './modules.scss';
import { HiCheckCircle } from 'react-icons/hi';

const Literacy2 = () => {
    return (
        <div className='module'>

            <h3>Saving and Spending</h3>

            <div className="mainWrapper">

                <div className="wrapper">

                    <p>
                        Through the activities in this course, participants will learn, adopt and improve good saving habits or practices.
                    </p>

                    <span>Course Topics:</span>

                    <div className="timeTable">

                        <ul className='module-lists'>
                            <li>What are savings and why save?</li>
                            <li>Set Savings Goals</li>
                            <li>Increase Your Savings</li>
                            <li>Decide How to Save</li>
                            <li>Compare Savings Services</li>
                        </ul>
                    </div>

                </div>


                <div className="outcome">
                    <h3>Course Outcomes...</h3>
                    <div className='items'>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Developing a savings plan</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Identify and join a savings group</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Introduction and onboarding to Regonow</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Literacy2