import React, { useState, useRef } from 'react';
import './contact.scss';
import emailjs from '@emailjs/browser';


const Contact = () => {

    const form = useRef();
    const [message, setMessage] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_dhfbji5',
            'template_1tv7vwk',
            form.current,
            'hpP76GmuOFmvYWeT-'
        )
            .then((result) => {
                console.log(result.text);
                setMessage('Thanks, We shall reply ASAP!');
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };



    return (
        <div className='contact'>

            {/* <div className="left">

                <div className="itemWrapper">
                    <div className="iconWrapper">
                        <MdLocationPin className='icon' />
                    </div>
                    <div className="contactText">
                        <h3>Yana Community Center</h3>
                        <h4 className='address'>P. O. Box 970259</h4>
                        <h4 className='address'>Kampala, Uganda</h4>
                    </div>
                </div>

                <hr className='contactHr' />

                <div className="itemWrapper">
                    <div className="iconWrapper">
                        <MdLocalPhone className='icon' />
                    </div>
                    <div className="contactText">
                        <h3>+256 775 326157</h3>
                        <p>Mon-Fri, 8am until 5pm</p>
                    </div>
                </div>

                <hr className='contactHr' />

                <div className="itemWrapper">
                    <div className="iconWrapper">
                        <MdEmail className='icon' />
                    </div>
                    <div className="contactText">
                        <span>info@regofoundation.org</span>
                        <p>We reply within 24 hours</p>
                    </div>
                </div>

            </div> */}

            <div className="right">
                <div className="top">
                    <p>PARTNER WITH US</p>
                    <h1>Host a Software Development Intern</h1>
                </div>

                <form ref={form} onSubmit={sendEmail}>

                    <div className="formSec1">

                        <input className="inputWrapper" type="text" name="company_name" placeholder='Company Name' />
                        <input className="inputWrapper" type="email" name="company_email" placeholder='Email' />

                    </div>
                    <div className="formSec1">
                        <input className="inputWrapper" type="text" name="company_person" placeholder='Contact Person' />
                        <input className="inputWrapper" type="text" name="company_phone" placeholder='Phone Number' />
                    </div>

                    <textarea name="message" rows="10" placeholder='Message' />
                    <input type="submit" value="Send" className="contactFormBtn" />
                    {message && <span>Thanks, We shall reply ASAP!</span>}

                </form>


            </div>

        </div>
    )
}

export default Contact