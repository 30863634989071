import React from 'react';
import './home.scss';
// import { motion } from "framer-motion";
import heroVid from '../../assets/heroVid.mp4';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";

import { useSpring, animated } from 'react-spring';
import { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';

function Number({ n }) {
    const { number } = useSpring({
        from: { number: 0 },
        number: n,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
}


const Home = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [apply, setApply] = useState({
        firstname: "",
        lastname: "",
        email: "",
    });

    const handleChange = (e) => {
        setApply((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const [counterOn, setCounterOn] = useState(false);
    return (
        <div className="home">

            {/* HERO SECTION */}
            <div className='homeHero'>
                <div className='overlay'></div>
                <video src={heroVid} className='videoTag' autoPlay loop muted />

                <div className='content'>
                    {/* <h2>A Safe Space for Women and Girls to Evolve, Network and Create</h2> */}
                    <h2>A Safe Space for Young Women to Evolve, Network and Create</h2>
                </div>
            </div>


            {/* SECTION TWO */}
            <div className="secWrapper">

                <div className="top">

                    <div className="leftTop">
                        <p>WHAT WE DO</p>
                    </div>
                    <div className="leftBottom">
                        {/* <h3>Transforming the Lives of <strong style={{color:"#175E54", textDecoration:"underline", marginLeft:"5px"}}>Women and Girls</strong></h3> */}
                        <h3>Unlocking the Potential of <strong style={{color:"#175E54", textDecoration:"underline", marginLeft:"5px"}}>Young Women</strong></h3>
                        {/* <h4>Women and Girls</h4> */}
                    </div>

                </div>

                <div className="bottom">
                    <span>
                        Rego Foundation empowers and supports young women and girls living in underserved communities;
                        to reach their full potential and become self-sufficient by building their capacities in harnessing
                        livelihood strategies through entrepreneurial induction  and technology. Our goal is to achieve a free and equitable society where women and girls are incharge
                        of the desicions that affect their lives.
                    </span>

                </div>
            </div>


            {/* SECTION THREE */}
            <div className="thirdWrapper">
                <div className="top">
                    <p>OUR PROGRAMS</p>
                    <h3>Our Focus Areas</h3>
                </div>

                <div className="bottom">

                    <Link to="/sheboss-entreprenuership" className='link' onClick={scrollToTop}>
                    <div className="item">
                        <div className="imgWrapper">
                            <img src="/assets/group.png" alt="" />
                        </div>

                        <div className="text">
                            <h4>SheBoss Program</h4>
                            <span>Empowering young women through entrepreneurship and financial literacy</span>
                                <div className="textWrapper">
                                    <p>Learn more</p>
                                    <MdKeyboardDoubleArrowRight className="rightIcon" />
                                </div>
                        </div>
                    </div>
                    </Link>

                    <Link to="/regonow-savings" className='link' onClick={scrollToTop}>
                    <div className="item">

                        <div className="imgWrapper">
                            <img src="/assets/sharon.webp" alt="" />
                        </div>

                        <div className="text">
                            <h4>Regonow - Group Savings</h4>
                            <span>A simplified digital savings system designed for groups</span>


                                <div className="textWrapper">
                                    <p>Learn more</p>
                                    <MdKeyboardDoubleArrowRight className="rightIcon" />
                                </div>

                        </div>
                    </div>
                    </Link>

                    <Link to="/tech-scholars" className='link' onClick={scrollToTop}>
                    <div className="item">

                        <div className="imgWrapper">
                            <img src="/assets/women.webp" alt="" />
                        </div>

                        <div className="text">
                            <h4>Tech Scholars Program</h4>
                            <span>Equipping young women with market-relevant technical skills</span>
                                <div className="textWrapper">
                                    <p>Learn more</p>
                                    <MdKeyboardDoubleArrowRight className="rightIcon" />
                                </div>

                        </div>
                    </div>
                    </Link>

                </div>
            </div>


            {/* SECTION FOUR */}
            <div className="fourthWrapper">
                <div className="top">
                    <p>OUR IMPACT</p>
                    <h3>Our Impact in Numbers</h3>
                </div>
                <div className="bottom">
                    <div className="numbersTop">
                        <div className="item1">
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                <div className="wrapNumber">
                                    <h1>
                                        {counterOn && <Number n={8832} />}
                                    </h1>
                                    <FaPlus className='numIcon' />
                                </div>
                            </ScrollTrigger>
                            <p>WOMEN REACHED</p>
                            <div className="responsive">
                                <span>WOMEN</span>
                                <span>REACHED</span>
                            </div>
                        </div>
                        <div className="item2">
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                <div className="wrapNumber">
                                    <h1>
                                        {counterOn && <Number n={1645} />}
                                    </h1>
                                    <FaPlus className='numIcon' />
                                </div>
                            </ScrollTrigger>
                            <p>WOMEN TRAINED</p>
                            <div className="responsive">
                                <span>WOMEN</span>
                                <span>TRAINED</span>
                            </div>
                        </div>
                    </div>

                    <div className="numbersBottom">
                        <div className="item1">
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                <div className="wrapNumber">
                                    <h1>
                                        {counterOn && <Number n={525} />}
                                    </h1>
                                    <FaPlus className='numIcon' />
                                </div>
                            </ScrollTrigger>
                            <p>GIRLS MENTORED</p>
                            <div className="responsive">
                                <span>GIRLS</span>
                                <span>MENTORED</span>
                            </div>
                        </div>
                        <div className="item2">
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                <div className="wrapNumber">
                                    <h1>
                                        {counterOn && <Number n={28} />}
                                    </h1>
                                    <FaPlus className='numIcon' />
                                </div>
                            </ScrollTrigger>
                            <p>SKILLED VOLUNTEERS</p>
                            <div className="responsive">
                                <span>SKILLED</span>
                                <span>VOLUNTEERS</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* SECTION FIVE */}
            <div className="fifthWrapper">
                <div className="top">

                    <p>FEATURED PARTNERS</p>
                    {/* <h3>Together we reach those in need</h3> */}
                    <h3>Our Trusted Partners</h3>

                </div>

                <div className="bottom">

                    <div className="bottomItem">

                        <div className="item">
                            <img src="/assets/logos/tivLogo.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="/assets/logos/iixLogo.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="/assets/logos/responseLogo.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="/assets/logos/softLogo.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="/assets/logos/globalLogo.png" alt="" />
                        </div>
                        <div className="item">
                            <img src="/assets/logos/cepLogo.png" alt="" />
                        </div>

                    </div>

                </div>
            </div>

            <div className="subscribe">
                <div className="left">
                    <p>JOIN OUR COMMUNITY</p>
                    <h3>Join Our Newsletter</h3>
                    <div className="text1">
                        <span>
                            Keep up with the latest news and information from the
                        </span>
                        <span>
                            communities we serve by signing up to receive our newsletter!
                        </span>
                    </div>
                    <div className="text2">
                        <span>
                            Keep up with the latest news and information from the communities we
                            serve by signing up to receive our newsletter!
                        </span>
                    </div>
                </div>
                {/* <div className="right"> */}
                <form>
                    <div className="item">
                        <div className="formItem">
                            <input
                                name="firstname"
                                type="text"
                                required
                                placeholder="First Name"
                                onChange={handleChange}
                                value={apply.firstname}
                            />

                            <input
                                name="lastname"
                                type="text"
                                required
                                placeholder="Last Name"
                                onChange={handleChange}
                                value={apply.lastname}
                            />
                        </div>

                        <input
                            name="email"
                            type="text"
                            required
                            placeholder="Email Address"
                            onChange={handleChange}
                            value={apply.email}
                        />
                        <button type="submit">
                            <p>Submit</p>
                            <MdKeyboardDoubleArrowRight className="aboutIcon" />
                        </button>

                    </div>

                </form>

                {/* </div> */}
            </div>



        </div>
    )
}

export default Home