import React from 'react';
import './programs.scss';
import { Link } from 'react-router-dom';
import { MdArrowRightAlt } from 'react-icons/md';

const Savings = () => {
    return (
        <div className='savings'>

            <div className="saveHero">
                <img src='/assets/save.png' alt='' />
                <div className="top">
                    <h2>Regonow Group Savings</h2>
                    <p>A Simplified Digital Savings System Designed for Groups</p>
                </div>
            </div>

            <div className="headingText">
                <div className='logoWrapper'>
                    <img className="logoImage" src="/assets/dotlogo.png" alt="logo" />
                    <span className="logoText">regonow</span>
                </div>
                <span>
                    Rego Foundation is dedicated to fostering a culture of collaboration, innovation, and
                    inclusivity, developing impactful solutions that empower women, girls, and their families
                    to thrive. Our mission is to create a supportive ecosystem that enables young people to become
                    confident, self-sufficient, and holistic individuals, equipped to reach their full
                    potential and make a positive impact in their communities.
                </span>
            </div>


            <div className="regonow">

                <div className="saveItem">

                    <div className="left">
                        <div className="top">
                            <h1>Build Your </h1>
                            <h1>Wealth in Groups</h1>
                        </div>
                        <span>
                            Regonow offers a safe and secure platform for savings groups to manage and track their funds,
                            access affordable loans, and harness the power of data to unlock new financial opportunities.
                            Our innovative solution empowers groups to take control of their finances, make informed decisions,
                            and build a brighter financial future.
                        </span>

                        <a
                            href="https://rego-now.com/"
                            target="_blank" rel="noreferrer"
                            className='saveBtn'
                            style={{ textDecoration: 'none', color: 'white' }}
                        >
                            Learn More
                            <MdArrowRightAlt className='saveIcon' />
                        </a>

                    </div>

                    <div className="right">
                        <div className="imgWrapper">
                            <img src='/assets/save1.png' alt='' />
                        </div>
                    </div>

                </div>

            </div>


        </div>
    )
}

export default Savings