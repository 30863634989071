import React from 'react';
import './modules.scss';
import { HiCheckCircle } from 'react-icons/hi';

const Literacy4 = () => {
    return (
        <div className='module'>

        <h3>Investment</h3>

        <div className="mainWrapper">

            <div className="wrapper">

                <p>
                    In this module, participants will explore the options of earning money.
                </p>

                <span>Course Topics:</span>

                <div className="timeTable">

                    <ul className='module-lists'>
                        <li>What is Investment?</li>
                        <li>Different types of investment </li>
                        <li>Making investment decisions</li>
                        <li>Developing an investment plan and goal</li>
                        <li>Cost of Investment</li>
                    </ul>
                </div>

            </div>


            <div className="outcome">
                <h3>Course Outcomes...</h3>
                <div className='items'>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Preparing and Presenting a business plan</p>
                    </div>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Reasons for investing, opportunities and resources</p>
                    </div>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Where and how do you invest (Safe Investments)</p>
                    </div>
                   
                </div>
            </div>
        </div>

    </div>
    )
}

export default Literacy4