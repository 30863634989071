import React, { useState } from 'react';
import './programs.scss';
import Literacy1 from '../../modules/Literacy1';
import Literacy2 from '../../modules/Literacy2';
import Literacy3 from '../../modules/Literacy3';
import Literacy4 from '../../modules/Literacy4';
import Literacy5 from '../../modules/Literacy5';
import LiteracyInfo from '../../modules/LiteracyInfo';
import LiteracyList from '../../modules/LiteracyList';
import FeaturedSheboss from '../../components/featured/FeaturedSheboss';
import Literacy6 from '../../modules/Literacy6';
import Literacy7 from '../../modules/Literacy7';
import { HiCheckCircle } from 'react-icons/hi';


const Sheboss = () => {

    const [selected, setSelected] = useState("Course Info");
    const [business, setBusiness] = useState("Program Info");

    const list = [
        {
            id: 1,
            title: "Course Info",
        },
        {
            id: 2,
            title: "Module 1",
        },
        {
            id: 3,
            title: "Module 2",
        },
        {
            id: 4,
            title: "Module 3",
        },
        {
            id: 5,
            title: "Module 4",
        },
        {
            id: 6,
            title: "Module 5",
        },
        {
            id: 7,
            title: "Module 6",
        },
        {
            id: 8,
            title: "Module 7",
        },


    ]


    const renderCurrentSelection = () => {
        switch (selected) {
            case "Course Info":
                return <LiteracyInfo />;
            case "Module 1":
                return <Literacy1 />;
            case "Module 2":
                return <Literacy2 />;
            case "Module 3":
                return <Literacy3 />;
            case "Module 4":
                return <Literacy4 />;
            case "Module 5":
                return <Literacy5 />;
            case "Module 6":
                return <Literacy6 />;
            case "Module 7":
                return <Literacy7 />;
            default:
                return <LiteracyInfo />;
        }
    };



    return (
        <div className='sheboss'>

           <div className="shebossHero">
                <img src='/assets/sheboss.png' alt='' />
                <div className="top">
                    <h2>SheBoss Program</h2>
                    <p>Business Training Designed for Women</p>
                </div>
            </div>


            <div className="headingText">
                <span>SheBoss offers a full suite of <strong style={{color:"#175E54"}}>entrepreneurship</strong>  and <strong style={{color:"#175E54"}}>financial literacy</strong> skills
                    that empower young women to start or grow their own businesses. SheBoss
                    programs aims to promote women's economic participation by leveraging access to financial and
                    non-financial services; upon completion of the program, beneficiaries are onboarded onto
                    Regonow group savings platform, linked to working capital grants and subsequent micro loans.
                </span>

                <hr/>

                <div className="numbers">
                    <div className="item">
                        <h3>328</h3>
                        <span>JOBS CREATED</span>
                    </div>

                    <div className="item">
                        <h3>53</h3>
                        <span>NEW BUSINESSES</span>
                    </div>

                    <div className="item">
                        <h3>66</h3>
                        <span>SAVINGS GROUPS</span>
                    </div>

                </div>

            </div>


            <div className="business">
                <div className="left">
                    <h1>Entrepreneurship</h1>
                    <span>
                        The SheBoss Entrepreneurship program is designed for young women and girls in the informal sector.
                        Whether at the idea stage, learning a new skill or looking to scale your business, this program
                        will engage and inspire participants, while providing essential, hands-on skills to drive results.
                        Our comprehensive curriculum includes:
                    </span>
                    <div className='items'>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Essentials of Entrepreneurship</p>
                    </div>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Empathize and Prototype</p>
                    </div>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Business Model Innovation</p>
                    </div>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Product Costing: Understanding the Financials of Your Product</p>
                    </div>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Building Company / Business Culture</p>
                    </div>
                    <div className="info-item">
                        <HiCheckCircle className="icon" />
                        <p>Demand Creation: Launching and Growing your Product</p>
                    </div>

                </div>

                </div>

                <div className="bssRight">

                    <div className="item1">
                        <img src="/assets/business1.png" alt="" />
                        <div className="empty"></div>
                    </div>

                    <div className="item2">
                        <div className="modules">
                            <h4>What you will gain...</h4>
                            <ul>
                                <li>Essential innovation and entrepreneurship skills</li>
                                <li>Collaborate with and learn from fellow participants </li>
                                <li>Mentorship from industry leaders & incredible founders</li>
                                <li>Access to funding opportunities</li>
                                <li>and more ...</li>
                            </ul>
                        </div>
                    </div>

                    <div className="itemRes">
                        <div className="modules">
                            <h4>What you will gain...</h4>
                            <ul>
                                <li>Essential innovation and entrepreneurship skills</li>
                                <li>Collaborate with and learn from fellow participants </li>
                                <li>Mentorship from industry leaders & incredible founders</li>
                                <li>Access to funding opportunities</li>
                                <li>and more ...</li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>

            <div className="literacyModules">
                <h1>Financial Literacy</h1>

                <div className="courseModules" >
                    <ul className='listWrapper'>
                        {list.map((item) => (
                            <LiteracyList
                                id={item.title}
                                active={selected === item.title}
                                setSelected={setSelected}
                                className={selected ? "listItem active" : "listItem"}
                            />
                        ))}
                    </ul>

                    <div className="infoContainer">
                        {renderCurrentSelection()}
                    </div>

                </div>


            </div>

            <FeaturedSheboss/>

        </div>
    )
}

export default Sheboss