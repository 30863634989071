import React from 'react';
import './about.scss';
import heroVid from '../../assets/heroVid.mp4';

const Impact = () => {
    return (
        <div className='impact'>

            <div className="impactHero">
                <img src='/assets/impact.png' alt='' />
                <div className="top">
                    <h2>Impact Stories</h2>
                </div>
            </div>

            <div className="itemWrapper">

                <div className="titles">
                    <div className="item">
                        <span>Name:</span>
                        <h4>Harriet Agasiru</h4>
                    </div>
                    <div className="item">
                        <span>Project:</span>
                        <h4>SheBoss - Program</h4>
                    </div>
                    <div className="item">
                        <span>Graduation:</span>
                        <h4>November 2020</h4>
                    </div>
                </div>

                <div className="impactObject">

                    <div className="imgWrapper">
                        <video src={heroVid} className='videoTag' controls loop poster="/assets/placeholder.png" />
                    </div>

                    <div className="objects">
                        <div className="wrapper">
                            <h3>From the Classroom to Roasting maize - The Impact of Covid-19</h3>
                            <span>
                            The COVID-19 pandemic worsened existing gender inequalities and vulnerabilities for
                             women and girls, as measures like school closures and total lockdowns took hold. The
                             crisis caused widespread job disruptions, particularly in the education sector.
                            </span>

                            <span>
                            Harriet Agasiru, a professional teacher, was among those affected, forced to resort to roasting
                             maize on the roadside to support her family. However, through the NextWave program by
                             The Innovation Village in partnership with MasterCard Founation, Rego Foundation has empowered over 700 women with new skills and
                             created 198 dignified employment opportunities, providing a lifeline for women like Harriet.
                            </span>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Impact