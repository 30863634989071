import React from 'react';
import './modules.scss';
import { HiCheckCircle } from 'react-icons/hi';

const Literacy6 = () => {
    return (
        <div className='module'>

            <h3>Retirement / Planning for old age</h3>

            <div className="mainWrapper">

                <div className="wrapper">

                    <p> In
                        our society people retire either voluntarily or are forced out of employment. Some people do
                        not stop work completely because they do not plan early enough for their retirement.
                        Transitioning from working life to retirement needs careful financial planning and decision making.
                    </p>

                    <span>Course Topics:</span>

                    <div className="timeTable">

                        <ul className='module-lists'>
                            <li>What is planning for old age or retirement?</li>
                            <li>Factors that affect planning for old age/retirement</li>
                            <li>Planning for retirement and its benefits</li>
                            <li>The different forms of retirement</li>
                            <li>Increasing your income during retirement</li>
                        </ul>
                    </div>

                </div>


                <div className="outcome">
                    <h3>Course Outcomes...</h3>
                    <div className='items'>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Preparing a retirement plan</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Understanding benefits of a retirement plan</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Understanding how to increase income in retirement</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Literacy6