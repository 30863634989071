import React from 'react';
import './modules.scss';
import { HiCheckCircle } from 'react-icons/hi';

const Literacy5 = () => {
    return (
        <div className='module'>

            <h3>Insurance</h3>

            <div className="mainWrapper">

                <div className="wrapper">

                    <p>In this module, participants will learn how to protect their families and property when confronted
                        with unexpected events. They will also learn about a financial product that offers protection. </p>

                    <span>Course Topics:</span>

                    <div className="timeTable">

                        <ul className='module-lists'>
                            <li>Understanding risk</li>
                            <li>Responding to risk</li>
                            <li>Introduction to insurance</li>
                            <li>Insurance terms</li>
                            <li>Types of insurance</li>
                        </ul>
                    </div>

                </div>


                <div className="outcome">
                <h3>Course Outcomes...</h3>
                    <div className='items'>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>What you need to know about Insurance</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>What, why, when, how and where to insure</p>
                        </div>
                        <div className="info-item">
                            <HiCheckCircle className="icon" />
                            <p>Identifying suitable insurance products</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Literacy5